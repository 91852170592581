/* Variables */

$primary-blue: #213E9B;

$primary-gold: #FFD524;

$white: #ffffff;

/* Styles */

body {
	background-color: $primary-blue; 
}

main.vert-middle {
	position: relative;
	margin: 0 auto;
}

.coming-soon {
	color: $white;
	display: block;
	font-family: 'Proxima Nova', Arial, sans-serif;
	font-size: 24px;
	font-weight: bold;
	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
}